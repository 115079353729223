import styled from '@emotion/styled'
import { Accordion } from 'app/components/Common/Accordion'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import React, { memo } from 'react'

export interface Props {
  pretitle?: string
  title?: string
  subtitle?: string
  description?: string
  faqList?: any
  claim?: string
  button?: ButtonProps
}

export const BlockFAQ = memo(function BlockFAQ({
  pretitle,
  title,
  subtitle,
  description,
  faqList,
  claim,
  button,
}: Props) {
  return (
    <Container>
      {pretitle ? (
        <FadeInUp>
          <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
        </FadeInUp>
      ) : null}

      <WrapperText>
        <Left>
          {title ? <Title text={title} /> : null}
          {subtitle ? (
            <SubtitleWrapper>
              <FadeInUp>
                <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
              </FadeInUp>
            </SubtitleWrapper>
          ) : null}
        </Left>
        <Right>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Right>
      </WrapperText>

      <WrapperAccordion>
        <Accordion items={faqList} color="dark" variant="half-width" />
      </WrapperAccordion>

      <WrapperBottom>
        <Claim text={claim} />
        {button ? (
          <FadeInUp>
            <StyledButton variant="full-dark-bg" {...button} />
          </FadeInUp>
        ) : null}
      </WrapperBottom>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 6.66vw;

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const WrapperText = styled.div`
  display: flex;
  margin-top: 3vw;

  @media (max-width: 991px) {
    display: block;
    margin-top: 0;
  }
`

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  flex: 0 1 auto;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`

const Left = styled.div`
  padding: 0 2.7vw;
  position: relative;
  width: 50%;
  flex-flow: column;

  &::before {
    top: 0;
    left: 0;
    content: ' ';
    width: 1px;
    height: 100%;
    position: absolute;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    display: block;
  }

  @media (max-width: 991px) {
    width: 100%;
    display: block;
  }
`

const Title = styled(AnimatedTitle)`
  font-size: 3vw;
  margin: 0 0 1.5vw 0;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 20px 0;
  }
`

const SubtitleWrapper = styled.div`
  width: 100%;
  align-self: flex-end;
`
const Subtitle = styled.h4`
  font-size: 1.45vw;
  font-weight: 300;

  @media (max-width: 991px) {
    font-size: 20px;
  }
`

const Right = styled.div`
  width: 50%;
  align-self: center;
  padding-right: 10vw;
  padding-left: 3.6vw;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
`
const Description = styled.div``

const WrapperAccordion = styled.div`
  margin-top: 3vw;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`

const WrapperBottom = styled.div`
  text-align: center;
  margin-top: 6vw;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`
const Claim = styled(AnimatedTitle)`
  font-size: 2.2vw;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 22px;
    white-space: normal;
  }
`
const StyledButton = styled(Button)`
  margin: 2.5vw auto 0 auto;

  @media (max-width: 991px) {
    margin: 30px auto 0 auto;
  }
`
